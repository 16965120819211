<template>
    <div
      class="flex flex-col px-4 space-y-10 lg:space-y-10 lg:px-0"
    >
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'NeeraGrid',
}
</script>
<style scoped>

</style>