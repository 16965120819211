<template>
  <router-link
    :to="linkTo"
    class="py-2 px-4 saoltext text-np-glimpse rounded-np-10 border border-np-glimpse whitespace-nowrap hover:opacity-80 flex md:hidden flex-row justify-center space-x-2"
  >
    <div>
      {{ text }}
    </div>
    <div class="flex transition duration-200 anchor-arrow">
      <img src="@/assets/neera-arrow.svg" />
    </div>
  </router-link>
</template>
<script>
export default {
  name: "NeeraOutlineArrowButton",
  props: {
    text: {
      type: [String],
    },
    linkTo: {
      type: [String],
    },
  },
};
</script>
