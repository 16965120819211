<template>
  <div>
    <img :class="classImage" :src="imageUrl" />
  </div>
  <div class="text-left lg:pl-8 py-2 space-y-4 max-w-screen-lg/2">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "NeeraDetailRow",
  props: {
    title: {
      type: [String],
    },
    imageUrl: {
      type: [String],
    },
    classImage: {
      type: [String],
      default: "img-para w-full",
    },
  },
};
</script>
<style scoped></style>
