<template>
  <div class="grid grid-cols-2 gap-np-sm">
    <div>
      <div class="flex flex-row h-12 w-auto">
        <div class="flex-none w-12 neera-text-green">
          <p class="text-np-40 text-center">{{ size }}</p>
        </div>
        <p class="neera-text-green items-center text-np-base pl-6">sqm</p>
      </div>
    </div>
    <div>
      <div class="flex flex-row h-12 w-auto">
        <div class="flex-none w-12 neera-text-green">
          <p class="text-np-40 text-center">{{ unit }}</p>
        </div>
        <p class="neera-text-green items-center text-np-base pl-6">units</p>
      </div>
    </div>
    <div v-for="(highlight, index) in highlights" :key="index">
      <div class="flex flex-row h-10 w-auto">
        <div
          class="flex-none h-full w-12 flex items-center justify-center text-center"
        >
          <img class="icon" :src="highlight.iconUrl" />
        </div>
        <div class="flex items-center neera-text-green pl-6 text-np-base-20">
          <p v-html="highlight.name"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    size: {
      type: [String],
    },
    unit: {
      type: [String],
    },
    highlights: {
      type: Array,
    },
  },
};
</script>
