<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-4 lg:gap-y-8">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "NeeraGridRow",
};
</script>
<style scoped></style>
