<template>
  <div class="inline-block">
    <router-link v-if="linkTo" :to="linkTo">
      <div
        class="py-2 px-4 saoltext text-np-glimpse rounded-np-10 border border-np-glimpse whitespace-nowrap hover:bg-np-glimpse hover:text-white"
      >
        {{ text }}
      </div>
    </router-link>
    <a v-else-if="isBooking" @click="checkAvailablity" class="cursor-pointer">
      <div
        class="py-2 px-4 saoltext text-np-glimpse rounded-np-10 border border-np-glimpse whitespace-nowrap hover:bg-np-glimpse hover:text-white"
      >
        {{ text }}
      </div>
    </a>

    <a v-else-if="tel" :href="tel">
      <div
        class="py-2 px-4 saoltext text-np-glimpse rounded-np-10 border border-np-glimpse whitespace-nowrap hover:bg-np-glimpse hover:text-white"
      >
        {{ text }}
      </div>
    </a>
    <a v-else @click="downloadFile" class="cursor-pointer">
      <div
        class="py-2 px-4 saoltext text-np-glimpse rounded-np-10 border border-np-glimpse whitespace-nowrap hover:bg-np-glimpse hover:text-white"
      >
        {{ text }}
      </div>
    </a>
  </div>
</template>
<script>
import { getSiteminderLinkDefault } from "@/api/siteminder";
import { getStaticFileBySlug } from "@/api/endpoints/staticfiles";
import to from "await-to-js";

export default {
  name: "NeeraOutlineButton",
  props: {
    text: {
      type: [String],
    },
    linkTo: {
      type: [String],
    },
    tel: {
      type: [String],
    },
    staticFileSlug: {
      type: [String],
    },
    isBooking: {
      type: [Boolean],
      default: false,
    },
  },
  methods: {
    checkAvailablity() {
      const url = getSiteminderLinkDefault();
      if (this.$vssWidth >= 1024) {
        window.open(url);
      } else {
        window.location.href = url;
      }
    },
    async downloadFile() {
      const [err, response] = await to(getStaticFileBySlug(this.staticFileSlug))

      var url
      if(response.data.data[0].attributes.content.length > 0){
        // IF component is File get url from file
        if(response.data.data[0].attributes.content[0].__component === "static-file.upload-file"){
          url = response.data.data[0].attributes.content[0].file.data.attributes.url
        }else{ //IF component is url get data from field url
          url = response.data.data[0].attributes.content[0].url
        }
      }else{
         url = response.data.data[0].attributes.file.data.attributes.url
      }
      
      if (this.$vssWidth >= 1024) {
        window.open(url);
      } else {
        window.location.href = url;
      }
    },
  },
};
</script>
