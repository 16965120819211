<template>
  <div class="experiences" data-view>
    <hero-with-image
      title="better living with our rooms"
      pre-description="<span
      class='saoltext whitespace-nowrap'
      >‘neera retreat’</span
    > offers 53 rooms and suites that are harmoniously and stylishly arranged in three wings "
      description="
    <p>
      – <span class='italic'>sun wing</span>, <span class='italic'>moon wing</span>, and <span class='italic'>cloud wing</span>, where guests can choose to indulge in the serenity of lush, tropical gardens or marvel at a picturesque river that is rich in history and culture.
    </p>
    "
      class="mb-18 lg:mb-20 lg:mt-8"
      :imageUrl="require('@/assets/rooms/Room_AllRooms_01-Cover.png')"
    />
    <!-- <item-navigation :items="navigations" default-tab="studio" /> -->
    <neera-grid id="spaces-content" class="border-top">
      <neera-grid-row
        data-aos="fade-up"
        v-for="(item, index) in rooms"
        :key="index"
        :id="item.link ? item.link : ''"
      >
        <neera-detail-row
          :classImage="`img-para w-full${
            index == 0 ? ' rounded-tr-np-lg' : ''
          }`"
          :imageUrl="item.image_url"
        >
          <p
            :id="item.name"
            class="text-np-glimpse saoltext font-light text-np-2xl lg:text-np-3xl pb-6"
          >
            {{ item.name
            }}<sup class="text-np-base lg:text-np-24">{{
              `(${item.type})`
            }}</sup>
          </p>
          <p
            class="robototext text-np-base lg:text-np-xl text-np-green font-light content-para pb-10"
            v-html="item.intro"
          ></p>
          <div>
            <highlight-item
              :size="item.size"
              :unit="item.unit"
              :highlights="item.highlights"
            />
            <div class="grid grid-cols-2 md:grid-cols-1 gap-6 md:gap-0">
              <div class="w-full pt-11">
                <neera-anchor
                  class="hidden md:flex"
                  :linkTo="`/rooms/${item.name}`"
                >
                  view details
                </neera-anchor>
                <neera-outline-arrow-button
                  class="flex md:hidden"
                  :linkTo="`/rooms/${item.name}`"
                  text="view details"
                />
              </div>
              <div class="w-full md:w-auto pt-11 md:pt-6">
                <neera-outline-button
                  :linkTo="false"
                  :is-booking="true"
                  class="text-center w-full md:w-auto"
                  text="book now"
                />
              </div>
            </div>
          </div>
        </neera-detail-row>
      </neera-grid-row>
    </neera-grid>
    <quote-break
      data-aos="fade-up"
      class="my-18 lg:my-20"
      title='<p class="text-np-3xl saoltext">zero waste facilities</p>'
      description="we aim to cut the use of single-use packaging by providing reusable packaging in-room amenities; organic bulk shampoo, soap and lotion dispenser, tooth tab (chewable toothpaste), refillable glass bottles, and local zero waste snack minibar."
    />
    <photo-horizon
      data-aos="fade-up"
      :photos="images"
      class="my-18 lg:my-20 mx-4 lg:mx-8"
    />
    <other-experiences
      data-aos="fade-up"
      exclude-path="/experiences/eco-wedding"
    />
  </div>
</template>

<script>
import OtherExperiences from "@/components/OtherExperiences.vue";
import QuoteBreak from "../../components/QuoteBreak.vue";
import PhotoHorizon from "../../components/PhotoHorizon.vue";
import HeroWithImage from "../../components/HeroWithImage.vue";
import NeeraOutlineButton from "@/components/NeeraOutlineButton.vue";
import NeeraOutlineArrowButton from "@/components/NeeraOutlineArrowButton.vue";
import NeeraGrid from "../../components/layout/NeeraGrid.vue";
import NeeraGridRow from "../../components/layout/NeeraGridRow.vue";
import NeeraDetailRow from "../../components/layout/NeeraDetailRow.vue";
import NeeraAnchor from "../../components/NeeraAnchor.vue";
import AOS from "aos";
import config from "@/config";
import HighlightItem from "./components/HighlightItem.vue";

export default {
  components: {
    HeroWithImage,
    OtherExperiences,
    QuoteBreak,
    PhotoHorizon,
    NeeraOutlineButton,
    NeeraOutlineArrowButton,
    NeeraGrid,
    NeeraGridRow,
    NeeraDetailRow,
    NeeraAnchor,
    HighlightItem,
  },
  name: "Rooms",
  created() {
    const title = "better living with our rooms";
    const description =
      "‘neera retreat’ offers 53 rooms and suites that are harmoniously and stylishly arranged in three wings. – sun wing, moon wing, and cloud wing, where guests can choose to indulge in the serenity of lush, tropical gardens or marvel at a picturesque river that is rich in history and culture.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });
  },
  computed: {
    rooms() {
      return Object.values(config.rooms);
    },
  },
  data() {
    return {
      navigations: [
        {
          name: "well",
          link: "studio",
        },
        {
          name: "pond",
          link: "deluxe",
        },
        {
          name: "mindful",
          link: "signature-deluxe",
        },
        {
          name: "lagoon",
          link: "family-suite",
        },
        {
          name: "lake",
          link: "paranomic-suite",
        },
      ],
      images: [
        {
          image_url: require("@/assets/rooms/Rooms_03_zerowastefacilities_01.png"),
          mobile_image_url: require("@/assets/rooms/Rooms_03_zerowastefacilities_01.png"),
        },
        {
          image_url: require("@/assets/rooms/Rooms_03_zerowastefacilities_02.png"),
          mobile_image_url: require("@/assets/rooms/Rooms_03_zerowastefacilities_02.png"),
        },
        {
          image_url: require("@/assets/rooms/Room_AllRooms_07-zerowastefacilities_03.png"),
          mobile_image_url: require("@/assets/rooms/Room_AllRooms_07-zerowastefacilities_03.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.title {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #905235;
}

.border-top {
  border-top: 1px solid #cecdc8;
  padding-top: 80px;
}

sup {
  top: -20px;
}

.booking_button {
  color: #994e2e;
  border: 1px solid #994e2e;
  border-radius: 10px;
}

.menu-content {
  border-top: 1px solid #cecdc8;
}

.underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}
</style>
