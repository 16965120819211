<template>
  <div class="py-10 lg:py-20 bg-np-fog">
    <div class="px-4 lg:px-8 max-w-screen-lg mx-auto space-y-6">
      <div>
        <p class="header-special-offer text-left text-np-2xl lg:text-np-3xl">
          other experiences
        </p>
      </div>

      <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-8 gap-y-10 lg:gap-y-0">
        <router-link
          v-for="(item, index) in currentItems"
          :key="index"
          :to="`${item.path}`"
        >
          <div class="flex flex-col justify-center w-full space-y-4">
            <div
              class="relative w-full aspect-4-3-landscape rounded-tr-neera overflow-hidden"
            >
              <img
                class="absolute object-cover w-full h-full"
                :src="item.image_url"
              />
            </div>
            <div class="flex flex-col text-left space-y-2">
              <p
                class="font-light text-np-xl saoltext text-np-green"
                v-html="item.title"
              ></p>
              <p
                class="description-special-offer text-np-green text-np-base font-light"
                v-html="item.description"
              ></p>
              <neera-anchor :linkTo="`${item.path}`" class="text-np-base">
                {{ item.exploreButton }}
              </neera-anchor>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import NeeraAnchor from "./NeeraAnchor.vue";

export default {
  name: "SpecialOffer",
  components: {
    NeeraAnchor,
  },
  props: {
    titleList: {
      type: [String],
      default: "",
    },
    type: {
      type: [String],
      default: "experiences",
    },
    excludePath: {
      type: [String],
      default: "",
    },
  },
  data() {
    return {
      lang: "en",
      special_items: [
        {
          title: "experience eco–wedding",
          path: "/experiences/eco-wedding",
          image_url: require("@/assets/experiences/thumbnail/wedding.png"),
          description:
            "our eco-wedding packages focus on eco-friendly, recyclable, or reusable items",
          exploreButton: "explore eco–wedding",
        },
        {
          title: "experience neera space",
          path: "/experiences/spaces",
          image_url: require("@/assets/experiences/thumbnail/space.png"),
          description:
            "<span class='saoltext'>‘neera’</span> creates spaces to offer deeper thoughts and connections through nature, design, and mindfulness.",
          exploreButton: "explore our spaces",
        },
        {
          title: "experience neera activities",
          path: "/experiences/activities",
          image_url: require("@/assets/experiences/thumbnail/activity.png"),
          description:
            "with one-of-a-kind experiences, we look to combine mindful, fun, active, and educational activities to elevate your stay while at <span class='saoltext'>‘neera’</span>.",
          exploreButton: "explore our activities",
        },
      ],
    };
  },
  computed: {
    currentItems() {
      let arr = [];
      for (const element of this.special_items) {
        if (element.path != this.excludePath) arr.push(element);
      }
      return arr;
    },
  },
};
</script>

<style scoped>
.booking {
  background: rgba(206, 205, 200, 1);
}

input {
  background: transparent;
  border: none;
  position: relative;
  display: block;
  outline: none;
  width: 10vw;
  height: 42px;
  padding: 10px;
  color: #333;
  border: 0;
  outline: 0;
  background: transparent;
}
::-webkit-input-placeholder {
  color: #333 !important;
}
:-moz-placeholder {
  color: #333 !important;
}
::-moz-placeholder {
  color: #333 !important;
}
:-ms-input-placeholder {
  color: #333 !important;
}

button {
  border-radius: 10px;
  height: 42px;
  border: 1px solid;
  border-image-source: linear-gradient(0deg, #050505, #050505),
    linear-gradient(0deg, #2f342b, #2f342b);
}

.border-bottom-dash {
  border-bottom: 1px solid black;
  border-style: dashed;
}

.text-litepie-secondary-700,
.placeholder-litepie-secondary-400,
.text-litepie-secondary-100,
.placeholder-litepie-secondary-500 {
  color: #666;
}

.subtitle {
  font-style: normal;
  font-weight: 300;
}

.header2 {
  font-weight: 300;
}

.header {
  width: 100%;
  height: 725px;
}

.img-para {
  width: 459px;
  height: 652px;
}

.title-para {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.header-special-offer {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.title-special-offer {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.description-special-offer {
  font-style: normal;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 50px;
}

.content-para {
  font-family: "Roboto";
  color: #5c623f;
}

.padding {
  padding-left: 10%;
  padding-right: 10%;
}

.special-offer {
  background: #e7e5dc;
}

.gallery-label {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #f0efea;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img {
  border-radius: 0px 40px 0px 0px;
}
</style>
